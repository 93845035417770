import React from 'react';
import Link from 'gatsby-link';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

const PageNotFound = () => (
  <Layout>
    <Helmet>
      <title>Error 404 - Page Not Found</title>
      <meta name="description" content="Error 404 - Page Not Found." />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Error 404 - Page Not Found</h1>
          </header>

          <div className="row">
            <div className="12u">
              <p>Sorry that page doesn&apos;t exist!</p>
              <p>Please choose from the links below:</p>
              <ul className="links">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/media">Media</Link>
                </li>
                <li>
                  <Link to="/songs">Songs</Link>
                </li>
                <li>
                  <Link to="/reviews">Reviews</Link>
                </li>
                <li>
                  <Link to="/frequently-asked-questions">FAQ</Link>
                </li>
                <li>
                  <Link to="/#contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default PageNotFound;
